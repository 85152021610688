import { Repulser } from "./Repulser";
export async function loadExternalRepulseInteraction(engine) {
    await engine.addInteractor("externalRepulse", (container) => new Repulser(container));
}
export * from "./Options/Classes/RepulseBase";
export * from "./Options/Classes/RepulseDiv";
export * from "./Options/Classes/Repulse";
export * from "./Options/Interfaces/IRepulseBase";
export * from "./Options/Interfaces/IRepulseDiv";
export * from "./Options/Interfaces/IRepulse";
